<!-- App.vue -->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  // Component logic goes here
};
</script>

<style>
*{
  padding: 0;
  margin: 0;
  background-color: #000000;
}
</style>
